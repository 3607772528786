<template>
  <div class="container-fluid py-3">
    <products-section :is-owner="isOwner" />
    <div class="row">
      <div class="col-12">
        <b-pagination align="center" v-model="currentPage" :total-rows="rows" :per-page="perPage"></b-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import ProductsSection from './partials/Product'

export default {
  components: {
    ProductsSection
  },
  data () {
    return {
      isOwner: false,
      slug: this.$route.params.slug,
      currentPage: 1,
      rows: 1,
      perPage: 1
    }
  },
  computed: {
    ...mapGetters([
      'console',
      'lang',
      'user'
    ])
  },
  watch: {
    currentPage (currentPage) {
      this.$router.push({ name: 'ShopCategory', params: { lang: this.lang, console: this.console, slug: this.slug }, query: { page: currentPage } })
      this.fetchData()
    }
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    setPage () {
      const page = this.$route.query.page
      if (page) {
        return page
      } else {
        return 1
      }
    },
    fetchData () {
      const params = {
        page: this.setPage()
      }
      const path = `${this.lang}/console/${this.console}/shop/subcategories/${this.slug}`
      this.$axios.get(path, { params }).then(response => {
        const data = response.data
        this.$store.dispatch('SET_PRODUCTS',  data.products)
        this.isOwner = data.owner
      })
    }
  }
}
</script>
